document.addEventListener("turbolinks:load", function () {
  function checkZodiacForm() {
    if (!$("#zodiac_month").find(":selected").val() || !$("#zodiac_day").find(":selected").val() || $("#zodiac_year").val().length < 1) {
      $("#zodiac_submit").prop("disabled", true);
      $("#zodiac_submit").data("fulfilled", "true");
    } else {
      $("#zodiac_submit").prop("disabled", false);
      $("#zodiac_submit").data("fulfilled", "false");
    };
  }

  function findZodiacInfo() {
    var twelveyearcycle = $("#zodiacVariables").data("zodiac-cycle");
    var trines = $("#zodiacVariables").data("zodiac-trines");
    var elements = $("#zodiacVariables").data("zodiac-elements");
    var creatures = $("#zodiacVariables").data("zodiac-creatures");
    var moons = $("#zodiacVariables").data("zodiac-moons");
    var months = $("#zodiacVariables").data("zodiac-months");

    var year = $("#zodiac_year").val();
    var day = $("#zodiac_day").val();
    var cycleKey = (year - (Math.floor(year / 12) * 12)); // Find the cycle position in the array
    // THIS SHOULD HAVE BEEN A "-1" and not a "+1" Fix:
    if (cycleKey == 11) {
      var cycle = twelveyearcycle[0]; // Set the actual cycle within the array
    } else {
      var cycle = twelveyearcycle[cycleKey + 1]; // Set the actual cycle within the array
    }
    //
    var trine = trines[cycle[4] - 1]; // Set the trine
    var monthKey = $("#zodiac_month").prop("selectedIndex"); // Set the month within the array
    var month = months[monthKey - 1]; // Set the month

    // MOONS: 1, 7, 1, 7, 1, 7, 1, 7
    // CYCLE: 32
    // 1, (2-8), 9, (10-16), 17, (18-24), 25, (26-32)
    var moonPhases = [
      [1, "New Moon"],
      [2, "Waxing Crescent"],
      [9, "First Quarter"],
      [10, "Waxing Gibbous"],
      [17, "Full Moon"],
      [18, "Waning Gibbous"],
      [25, "Last Quarter"],
      [26, "Waning Crescent"]
    ]

    var daysTotal = parseInt(year) + (parseInt((monthKey - 1) * 30)) + parseInt(day); // Find the total days passed
    var moonCycle = daysTotal - (Math.floor(daysTotal / 32) * 32);
    var moon = "";
    var moonArrayPos = 0;
    // console.log(daysTotal);
    // console.log(moonCycle);

    for (var m = 0; m < moonPhases.length; m++) {
      if (moonCycle >= 26) {
        moon = moonPhases[7];
        moonArrayPos = 7;
      } else if (moonCycle >= moonPhases[m][0] && (moonCycle < moonPhases[m + 1][0])) {
        moon = moonPhases[m];
        moonArrayPos = m;
        break;
      };
    }

    // Find Creature
    var creatureName = cycle[0];
    for (var i = 0; i < creatures.length; i++) {
      if (creatureName === creatures[i][0]) break;
    }
    if (i >= creatures.length) {
      // console.log("not found");
    } else {
      // console.log("found at index " + i);
      var creature = creatures[i];
    }

    // Elements
    // Air(0), Fire(1), Earth(2), Water(3), Positive Energy(4), Negative Energy(5)
    var elementsObjects = [
      { name: "Wind", elementKey: [0] },
      { name: "Ice", elementKey: [3, 0] },
      { name: "Soil", elementKey: [2, 3] },
      { name: "Magma", elementKey: [2, 1] },
      { name: "Flame", elementKey: [1] },
      { name: "Radiance/Darkness", elementKey: [4, 5] },
      { name: "Stone", elementKey: [2] },
      { name: "Lightning", elementKey: [0, 2] },
      { name: "Smoke", elementKey: [0, 1] },
      { name: "Ash", elementKey: [1, 3] },
      { name: "Tide", elementKey: [3] },
      { name: "Darkness/Radiance", elementKey: [4, 5] }
    ]

    // var elementDescriptor = cycle[1];
    const elementArrayPos = elementsObjects.findIndex(e => e.name === cycle[1]);
    var elementArrayKeys = elementsObjects[elementArrayPos].elementKey;
    var elementsString = "";

    // Find all base elements, as some have multiple
    for (var p = 0; p < elementArrayKeys.length; p++) {
      // elements[elementArrayKeys[p]]
      elementsString +=
        '<div class="card">' +
        '<div class="card-header"><h5 class="mb-1">' + elements[elementArrayKeys[p]][0] + '</h5></div>' +
        '<div class="card-body">' +
        '<p class="mb-0">' + elements[elementArrayKeys[p]][1] + '</p>' +
        '</div><ul class="list-group list-group-flush border-top font-size-14">' +
        '<li class="list-group-item"><strong><i class="fad fa-brush fa-lg fa-fw"></i> Color:</strong> ' + elements[elementArrayKeys[p]][2] + '</li>' +
        '<li class="list-group-item"><strong><i class="fad fa-wheat fa-lg fa-fw"></i> Season:</strong> ' + elements[elementArrayKeys[p]][3] + '</li>' +
        '<li class="list-group-item"><strong><i class="fad fa-planet-ringed fa-lg fa-fw"></i> Planet:</strong> ' + elements[elementArrayKeys[p]][4] + '</li>' +
        '</ul></div>';
    }

    if ($("#zodiac_submit").data("fulfilled", "true")) {
      // Set Main Info Tab
      $("#home-tab-pane").html(
        '<p>Here is the main cycle information for this specific date! You can read more about the assigned Trine and Element by clicking the other tabs above.</p>' +
        '<div class="card"><div class="card-header bg-green-50"><h5 class="mb-1">12-Year Cycle</h5></div>' +
        '<ul class="list-group list-group-flush font-size-14">' +
          '<li class="list-group-item"><strong><i class="fad fa-' + creature[3] + ' fa-lg fa-fw"></i> Creature:</strong> ' + cycle[0] + '. ' + creature[2] + '</li>' +
          '<li class="list-group-item"><strong><i class="fad fa-circle-half-stroke fa-lg fa-fw"></i> Assigned Element:</strong> ' + cycle[1] + '</li>' +
        '<li class="list-group-item"><strong><i class="fad fa-draw-circle fa-lg fa-fw"></i> Base Elements:</strong> ' + cycle[2] + '</li>' +
        '<li class="list-group-item"><strong><i class="fad fa-circles-overlap fa-lg fa-fw"></i> Balance:</strong> ' + cycle[3] + '</li>' +
        '<li class="list-group-item"><strong><i class="fad fa-chart-pie fa-lg fa-fw"></i> Trine:</strong> ' + cycle[4] + '</li>' +
        '</ul></div>' +
        '<div class="card mb-4"><div class="card-header bg-green-50"><h5 class="mb-1">Lunar Phase</h5></div>' +
        '<ul class="list-group list-group-flush font-size-14">' +
          '<li class="list-group-item"><strong><i class="fad fa-moon fa-lg fa-fw"></i> Lunar Phase:</strong> ' + moons[moonArrayPos][3] + ' ' + moons[moonArrayPos][0] + '</li>' +
          '<li class="list-group-item"><strong><i class="fad fa-subtitles fa-lg fa-fw"></i> Description:</strong> ' + moons[moonArrayPos][1] + '</li>' +
        '</ul></div>' +
        '<div class="card mb-0"><div class="card-header bg-green-50"><h5 class="mb-1">Zodiac Month</h5></div>' +
        '<ul class="list-group list-group-flush font-size-14">' +
          '<li class="list-group-item"><strong><i class="fad fa-calendar-days fa-lg fa-fw"></i> Month <span class="text-muted">(Title)</span>:</strong> ' + month[0] + ' <span class="text-muted">(' + month[2] + ')</span></li>' +
          '<li class="list-group-item"><strong><i class="fad fa-stars fa-lg fa-fw"></i> Constellation:</strong> ' + month[1] + '</li>' +
          '<li class="list-group-item"><strong><i class="fad fa-calendar-day fa-lg fa-fw"></i> Favored Day:</strong> ' + month[3] + '</li>' +
          '<li class="list-group-item"><strong><i class="fad fa-subtitles fa-lg fa-fw"></i> Description:</strong> ' + month[4] + '</li>' +
        '</ul></div>'
      );

      // Set Trine Tab
      $("#trine-tab-pane").html(
        // Find the trine using the trine setting on the cycle array, subtract one since it starts with 0
        '<h5>' + trine[0] + ' Trine</h5>' +
        '<p>' + trine[1] + '</p>' +
        '<ul class="list-group list-group-flush border font-size-14">' +
          '<li class="list-group-item"><strong><i class="fad fa-paw-claws fa-lg fa-fw"></i> Creatures:</strong> ' + trine[2] + '</li>' +
          '<li class="list-group-item"><strong><i class="fad fa-screwdriver-wrench fa-lg fa-fw"></i> Professions:</strong> ' + trine[3] + '</li>' +
        '</ul>'
      );

      // Set Element Tab
      $("#element-tab-pane").html(
        elementsString
      );
    }
  }

  // Adjust button disabled property as form changes
  $("#zodiac_month").change(checkZodiacForm);
  $("#zodiac_day").change(checkZodiacForm);
  $("#zodiac_year").keyup(checkZodiacForm);

  $("#zodiacDateForm").submit(function (e) {
    e.preventDefault();
    findZodiacInfo();
    return false;
  });

  $("#zodiac_submit").click(function (e) {
    e.preventDefault();
    findZodiacInfo();
    return false;
  });

});
